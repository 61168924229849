export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحفظ التغييرات"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم الحفظ"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد؟"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ID)نسخ المعرف"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["اليوم على ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["البارحة على ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".ليس لديك الصلاحية للتحدث في هذه القناة"])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متصل"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلحاق رسالة"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكتب رسالتك"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع للأسفل"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل جديدة"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضغط"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرفع إلى"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤية الملف الشخصي"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الأدوار"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرد"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظر"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(tag)نسخ شعار المستخدم"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقتباس"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع إشارة مقروء"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات السيرفر"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغادرة السيرفر"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتم صوت القناة"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء كتم صوت القناة"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعارات"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الرموز التعبيرية"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات الإتصال"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات بدء التشغيل"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط البرنامج"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة البوتات"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجهة"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الحساب"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل التغييرات"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(العمل قيد التقدم) الميزات الغير المكتلمة"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدل ملفك الشخصي"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير كلمة السر"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد ربط جوجل درايف"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط جوجل درايف"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من الملف الشخصي"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".هذه المعلومات يمكن رؤيتها من طرف الجميع"])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير إعدادات الإشعارات الخاصة بك"])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صوت الإشعار"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يصدر صوتًا رائعًا لجذب انتباهك"])},
      "notification-mentions-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشارات أو الرسائل المباشرة فقط"])},
      "notification-mentions-only-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".يجعل ذلك الإشارات والرسائل المباشرة فقط تنبثق و تصدر صوتًا"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعار منبثق"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".احصل على الإشعارات المنبثقة حتى عندما يكون التطبيق مغلقًا"])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".قم بتحميل الرموز التعبيرية الجميلة الخاصة بك مجانًا! يجب أن يكون حجم الرموز التعبيرية 3 ميغا بايت أو أقل"])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["png ،jpg ،gif :أنواع الملفات المدعومة هي"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الرموز التعبيرية"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات بدء التشغيل لتطبيق سطح المكتب"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على سطح المكتب Nertivia لتعديل هذه الإعدادات، يجب عليك تنزيل تطبيق"])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند بدء التشغيل Nertivia افتح"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".شارك البرامج أو الألعاب التي تقوم بتشغيلها عن طريق إضافة التطبيقات أدناه. سيحل هذا محل حالتك المخصصة عند فتح البرنامج وسيعود مرة أخرى عند إغلاق البرنامج"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخاص بسطح المكتب Nertivia لاستخدام ميزة نشاط البرنامج، يجب عليك تنزيل تطبيق"])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".أنشئ بوتات كمستخدمين مما سيتيح لك استخدام واجهة برمجة التطبيقات الخاصة بنا لإنشاء روبوتات خيالية خاصة بك"])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف أوامر البوت للسماح للمستخدمين بمعرفة الأوامر الموجودة لبوتك بسهولة"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء بوت"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الأمر"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل البوت"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الأوامر"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء رابط دعوة"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ رابط دعوة"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الرمز"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إظهار الرمز"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ الرمز"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين الرمز"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف بوت"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت على وشك حذف حسابك"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما سيتم حذفه"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما لن يتم حذفه"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائلك المرسلة إلى السيرفرات العامة"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتطلبات"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغادرة أو حذف جميع السيرفرات"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف جميع البوتات"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف حسابي"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".GitHub repo في ال PR قد يلزم إعادة التحميل بعد تغيير اللغة. ساهم عن طريق إرسال"])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المترجمون"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".تغيير مظهر Nertivia"])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الألوان"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الوقت"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثيم الافتراضي"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dark"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عيد الهالوين 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثيمات محددة مسبقًا"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(CSS)تنسيقات مخصصة"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط التنقل"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".تمكين الميزات التجريبية أو غير المكتملة"])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".لا تتوفر ميزات العمل قيد التقدم الآن"])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأذونات"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأدمين"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل جميع الأذونات"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الرسائل"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الرسائل إلى قنوات السيرفر"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الأدوار"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل أو حذف الأدوار"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة القنوات"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل أو حذف القنوات"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرد"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرد مستخدم"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظر"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرد و تقييد مستخدم"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة القنوات"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الأدوار"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الدعوات"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إمكانية رؤية السيرفر"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الرموز التعبيرية"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الإشعارات"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمون المحظورون"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة المستخدمين"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف السيرفر"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة أدوارك"])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور الإفتراضي"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دور البوت"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء دور جديد"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".لا يمكن حذف أو تعيين هذا الدور لعضو آخر لأنه تم إنشاؤه بواسطة بوت. قم بإزالة البوت لإزالة هذا الدور"])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".لا يمكن حذف هذا الدور أو إلغاء تعيينه لأن هذا هو الدور الافتراضي الذي يتم تطبيقه على كل عضو"])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر لون الدور"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسم الدور"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الدور"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم حذف الدور"])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء الدور"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات السيرفر العامة"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة قناواتك"])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف القناة"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم حذف القناة"])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قناة جديدة"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".هذه قناة افتراضية. يمكنك تغيير القناة الافتراضية في الصفحة العامة. لا يمكن حذف القنوات الافتراضية"])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قناة جديدة"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال رسائل"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الحد الأقصى (ثواني)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قناة نصية"])},
      "category-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(العمل قيد التقدم) HTML قناة"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء حظر الأعضاء من هنا"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم إلغاء الحظر"])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الحظر"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".يمكن للمسؤولين فقط رؤية دعوات الجميع"])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء دعوات"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة مخصصة"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ الرابط المخصص"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء دعوة"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشئ"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستخدامات"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن مستخدم"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل إسم السيرفر للتأكيد"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم الحذف"])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".تغيير إعدادات الإشعارات"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".ستنطبق هذه الإعدادات عليك فقط ولن تؤثر على الآخرين"])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل الإشعارات"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتم الصوت"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطيل الإشعارات"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " …يكتب"])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "، ", _interpolate(_list(1)), " …يكتبون"])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "، ", _interpolate(_list(1)), "، ", _interpolate(_list(2)), " …يكتبون"])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " …يكتبون"])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصدقاء"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخيرة"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملاحظات المحفوظة"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة صديق"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعارات"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر لإضافة حالة مخصصة"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!قرأت الكل"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل مباشرة"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيرفرات"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إكتشاف"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".(tag)أضف صديقًا عن طريق كتابة اسم المستخدم والشعار"])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم الإضافة"])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة سيرفر"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".انضم إلى سيرفر عن طريق إدخال رمز دعوة"])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".قم بإنشاء السيرفر الخاص بك. ابدأ بتسمية السيرفر الخاص بك"])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنضمام"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متصل"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متصل"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعيد"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشغول"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتطلع للعب؟"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["الأعضاء (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["غير متصل (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصدقاء مشتركون"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارات"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منضم"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقوف مأقتا"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الصديق"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول الطلب"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الطلب"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة صديق"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل رسالة"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيرفرات مشتركة"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم التحميل"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!جاهز"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…يتم المصادقة"])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحديث متاح"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!تم النسخ"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غادر(ت) السيرفر"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم طرده(ا)"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حظره(ا)"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!إنضم(ت) للسيرفر"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الإتصال بالسيرفر"])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع معدل الحد الإقصى"])}
  }
}